import React, { useEffect } from 'react';
import '../Component/Carrer2.css';
// import videoSrc from '../Component/Images/bannerv.mp4';
import joinimg from '../Component/Images/diversecareer.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const Carrer2 = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 100,
    });
  }, []);

  return (
    <div className="carrer-section-container">
      <div className="carrer-career-content">
        <div className="carrer-lines-div" data-aos="fade-in" data-aos-delay="1000"></div>
        
        <div className="carrer-text-content">
          <h2 data-aos="fade-in" data-aos-delay="500">Diverse Career Opportunities</h2>
          <p data-aos="fade-in" data-aos-delay="500">Explore a wide range of career options in IT, from software development to project management.</p><br />

          <h2 data-aos="fade-in" data-aos-delay="1000">Professional Development</h2>
          <p data-aos="fade-in" data-aos-delay="1000">Grow your skills and expertise with our continuous learning and development programs.</p><br />

          <h2 data-aos="fade-in" data-aos-delay="1200">Supportive Work Environment</h2>
          <p data-aos="fade-in" data-aos-delay="1200">Join a team that values collaboration, innovation, and work-life balance.</p>
        </div>

        <div className="carrer-image-content">
          <img src={joinimg} alt="Join our team" className="carrer-joinimg" data-aos="flip-down" data-aos-delay="2500" />
        </div>
      </div>
    </div>
  );
};

export default Carrer2;
