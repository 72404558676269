
import React,{useEffect} from 'react';
import { Row, Col } from 'antd';
import './Clients_lists.css';
import image from '../Component/Images/career.jpg'
import img1 from '../Component/Images/priyanshi.png';
import img2 from '../Component/Images/apsmarthomes.jpg';
import img3 from '../Component/Images/Avflogo.png';
import img4 from '../Component/Images/shreekrishna.jpeg.jpg';
import img5 from '../Component/Images/CourierLogo.png';
import img6 from '../Component/Images/Rupesh CSC Logo.png';
import img7 from '../Component/Images/DHYEY ACADEMY_page-0001.jpg';
import img8 from '../Component/Images/Kamal trading.JPG';
import img9 from '../Component/Images/Info-fix.png';


const clients = [
  { src: img1, alt: 'priyanshi' },
  { src: img6, alt: 'rupesh grahak seva kendra' },
  { src: img7, alt: 'dhyey academy' },
  
  { src: img9, alt: 'infofix' },
  { src: img4, alt: 'shreekrishna' },
  { src: img2, alt: 'apsmarthomes' },
  { src: img3, alt: 'Avflogo' },

  { src: img5, alt: 'courier and losgistics' },
  { src: img8, alt: 'kamal trading company' },

];

const Clients = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []); // Trigger effect on pathname change
  
  return (
    <div className="clients-container">
      <h1 className="clients-title">OUR PRESTIGIOUS CLIENTS</h1>
      <Row>
      {clients.map((client, index) => (
      <Col className='client_map_col'>
      <img src={client.src} alt={client.alt}/>
      </Col>
         ))}
        </Row>
    </div>
  );
};

export default Clients;
