import React ,{useEffect}from 'react';
import 'animate.css';
import './Contact_Us.css';
import { FaPhone, FaGlobe, FaEnvelope, FaMapMarkerAlt ,FaPhoneAlt} from 'react-icons/fa';
import Enquiry from '../Component/Enquiry'

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []); // Trigger effect on pathname change
  
  return (
    <div>
      {/* <div className="contact-us-title">
        <h1 className="animate_animated animate_bounce">Contact Us</h1>
      </div> */}
      <Enquiry/>
      <div className="contact-us-container">
        <div className="contact-us-content">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14874.003876404788!2d81.6106939!3d21.2516285!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28dd860cf3c217%3A0x493efc4d076691fb!2sYashviTech%20IT%20Solution!5e0!3m2!1sen!2sin!4v1723615089545!5m2!1sen!2sin"
         width="600"
          height="450" 
        style={{border:'0'}}
         allowfullscreen=""
          loading="lazy"
           referrerpolicy="no-referrer-when-downgrade">
           </iframe>
          <div className="contact">
            <div>
              <h2 className="Head_Office"><FaMapMarkerAlt /> OFFICE ADDRESS</h2>
              <br />
              <p>
                Yashvitech IT Solution<br/>
                2nd Floor, Sahu Complex, near Disha College, Ramnagar, Kota, Raipur, Chhattisgarh 492001
              </p>
              <p>
              <a href="tel:${8640091593}" style={{ color: "black" }}><FaPhoneAlt />&nbsp;+91- 8640091593</a><br></br>
              </p>
              {/* <p>
                <FaGlobe /> <strong>Website:</strong> <a href="https://www.yashvitech.com/" target="_blank" rel="noopener noreferrer">www.yashvitech.com</a>
              </p> */}
              <p>
                <FaEnvelope />  <a href="mailto:info@shlokitsolution.com">&emsp;info@yashvitech.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
