import React, { useEffect } from 'react';
import { Row, Col, Image } from 'antd';
import imgy from '../Component/Images/carr22.png';
import carrer1img from '../Component/Images/carrer1img.png';
import carrer3img from '../Component/Images/carr3.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Carrer.css'

const CompanyHighlights = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []); // Trigger effect on pathname change
  

  return (
    <Row justify="center" gutter={[16, 16]}>
      <Col span={8}>
        <div className="highlight-item" data-aos="fade-up" data-aos-delay="300">
          <Image src={carrer1img} alt="Customer-centric Approach" className="company_img_carrer" data-aos="zoom-in" data-aos-delay="600"/>
          <h3 data-aos="fade-up" data-aos-delay="900">Customer-centric Approach</h3><br />
          <p data-aos="fade-right" data-aos-delay="1200">We prioritize our clients' needs and ensure top-notch service and support.</p>
        </div>
      </Col>
      <Col span={8}>
        <div className="highlight-item" data-aos="fade-up" data-aos-delay="600">
          <Image src={imgy} alt="Innovative Solutions" className="company_img_carrer" data-aos="zoom-in" data-aos-delay="900"/><br /><br />
          <h3 data-aos="fade-up" data-aos-delay="1200">Innovative Solutions</h3><br />
          <p data-aos="fade-right" data-aos-delay="1500">We provide cutting-edge technology solutions to help businesses thrive.</p>
        </div>
      </Col>
      <Col span={8}>
        <div className="highlight-item" data-aos="fade-up" data-aos-delay="900">
          <Image src={carrer3img} alt="Skilled Team" className="company_img_carrer" data-aos="zoom-in" data-aos-delay="1200"/>
          <h3 data-aos="fade-up" data-aos-delay="1500">Highly Skilled Team</h3><br />
          <p data-aos="fade-right" data-aos-delay="1800">Our team consists of experienced professionals dedicated to excellence.</p>
        </div>
      </Col>
    </Row>
  );
};

const CareerPage = () => (
  <div>
    <h2 className="career-page-title" data-aos="fade-down">Join Our Team at Yashvitech IT Solutions</h2>
    <h3 className="career-page-subtitle" data-aos="fade-down" data-aos-delay="300">We believe in nurturing talent and fostering growth.</h3>
    <CompanyHighlights /><br /><br />
    {/* Your existing "Why Choose Us?" component */}
  </div>
);

export default CareerPage;
