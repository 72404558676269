// import React, { useEffect } from 'react';
// import './Yash.css';
// import { Card } from 'antd';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import port1 from './Images/port1.jpg';
// import searchIcon from './Images/search1.jpeg';
// import port2 from './Images/portfolio2.jpg';
// import port3 from './Images/portfolio.3.jpg';
// import port4 from './Images/portfolio4.jpg';
// import port5 from './Images/port1.jpg';
// import port6 from './Images/port1.jpg';
// import background from './Images/portfolio_back.avif';

// const { Meta } = Card;

// function Yash() {
//     useEffect(() => {
//         AOS.init({
//             duration: 1500,
//             once: false,
//         });
//     }, []);

//     const Data = [
//         { ID: '1', yash: port1, link: 'https://apsmarthomes.com/', btn: 'apsmarthomes.com' },
//         { ID: '2', yash: port2, link: 'https://shreekrishnapowertools.com/', btn: 'shreekrishnapowertools.com' },
//         { ID: '3', yash: port3, link: 'https://myvehicles.in/', btn: 'myvehicles.in' },
//         { ID: '4', yash: port4, link: 'https://priyanshi.coalfeed.in/', btn: 'priyanshi.coalfeed.in' },
//         { ID: '5', yash: port5, link: 'https://www.w3schools.com/', btn: 'website' },
//         { ID: '6', yash: port6, link: 'https://www.google.com/', btn: 'website' },
//     ];

//     function handleLinkClick(link) {
//         window.open(link, '_blank');
//     }

//     return (
//         <div className="main">
//             <img className="portfolio_background_image" src={background} alt="background" />
//             <div className="container">
//                 {Data.map((item) => (
//                     <div key={item.ID} className="image-div" data-aos="flip-up">
//                         <Card
//                             hoverable
//                             cover={<img src={item.yash} onClick={() => handleLinkClick(item.link)} className="image" alt="portfolio" />}
//                         >
//                             <Meta />
//                             <div className="hover-content">
//                                 <img src={item.yash} onClick={() => handleLinkClick(item.link)} className="image" alt="portfolio" />
//                             </div>
//                             <button
//                                 type="button"
//                                 className="Yash_btn"
//                                 onClick={() => handleLinkClick(item.link)}
//                             >
//                                 {item.btn}
//                             </button>
//                         </Card>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }

// export default Yash;



import React, { useEffect } from 'react';
import './Yash.css';
import { Card } from 'antd';
import AOS from 'aos';
import 'aos/dist/aos.css';
import port1 from './Images/port1.jpg';
import searchIcon from './Images/search1.jpeg';
import port2 from './Images/portfolio2.jpg';
import port3 from './Images/portfolio.3.jpg';
import port4 from './Images/portfolio4.jpg';
import port5 from './Images/rupesh.jpg'
// import port6 from './Images/'
import background from './Images/portfolio_back.avif';

const { Meta } = Card;

function Yash() {
    useEffect(() => {
        AOS.init({
            duration: 1500,
            once: false,
            
        });
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top
      }, []); // Trigger effect on pathname change
      

    const Data = [
        { ID: '1', yash: port3, link: 'https://myvehicles.in/', btn: 'myvehicles.in' },
        { ID: '2', yash: port1, link: 'https://apsmarthomes.com/', btn: 'apsmarthomes.com' },
        { ID: '3', yash: port5, link: 'https://rupeshgrahaksevakendra.com/', btn: 'rupeshgrahaksevakendra.com' },
        { ID: '4', yash: port2, link: 'https://shreekrishnapowertools.com/', btn: 'shreekrishnapowertools.com' },
        { ID: '5', yash: 'dhyey.png', link: 'https://dhyeyacademy.co.in/', btn: 'dhyeyacademy.co.in' },
        { ID: '6', yash: port4, link: 'https://priyanshi.coalfeed.in/', btn: 'priyanshi.coalfeed.in' },
        
    ];

    function handleLinkClick(link) {
        window.open(link, '_blank');
    }

    return (
        <div className="main">
            <img className="portfolio_background_image" src={background} alt="background" />
            <div className="container">
                {Data.map((item) => (
                    <div key={item.ID} className="image-div" data-aos="flip-up">
                        <Card
                            hoverable
                            cover={<img src={item.yash} onClick={() => handleLinkClick(item.link)} className="image" alt="portfolio" />}
                        >
                            <Meta />
                            <div className="hover-content">
                                <img src={item.yash} onClick={() => handleLinkClick(item.link)} className="image" alt="portfolio" />
                            </div>
                            <button
                                type="button"
                                className="Yash_btn"
                                onClick={() => handleLinkClick(item.link)}
                            >
                                {item.btn}
                            </button>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Yash;