import React, { useState } from 'react';
import { Button } from 'antd';
import { MenuOutlined, CloseOutlined, HomeOutlined, LineChartOutlined, InfoCircleOutlined, GlobalOutlined, ShoppingCartOutlined, SolutionOutlined, TeamOutlined, FileDoneOutlined, PhoneOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import img from '../Component/Images/yashvitech.png';
import { FaPhoneAlt } from 'react-icons/fa';
import { GrGallery } from "react-icons/gr";
import './Header.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false); // Close the menu when a link is clicked
  };

  return (
    <nav className="navbar">
      <div className="hed">
        <div className="logo-btn-container">
          <div className="logo">
            <NavLink to="/"> <img src={img} alt="Yashvitech IT Logo" /></NavLink>
          </div>
          <NavLink to="/Training_internship" style={{ textDecoration: "none" }}>
            <Button className="btn" style={{ backgroundColor: 'gold', color: 'black', padding: '5px' }}>Training and Internship</Button>
          </NavLink>
        </div>
        <Button className="menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
        </Button>
      </div>
      <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`} style={{ backgroundColor: "#6a3997" }}>
        <li><NavLink exact to="/" activeClassName="active" onClick={handleLinkClick}><HomeOutlined /><span style={{ marginLeft: '7px' }}>Home</span></NavLink></li>
        <li className="dropdown">
          <NavLink to="/About" activeClassName="active" onClick={handleLinkClick}><InfoCircleOutlined /><span style={{ marginLeft: '7px' }}>About</span></NavLink>
          <ul className="dropdown-content"></ul>
        </li>
        <li><NavLink to="/Portfolio" activeClassName="active" onClick={handleLinkClick}><FileDoneOutlined /><span style={{ marginLeft: '7px' }}> Portfolio</span></NavLink></li>
        <li><NavLink to="/Clients" activeClassName="active" onClick={handleLinkClick}><TeamOutlined /><span style={{ marginLeft: '7px' }}>Clients</span></NavLink></li>
        <li className="dropdown">
          <NavLink to="/Gallery" activeClassName="active" onClick={handleLinkClick}><GrGallery /><span style={{ marginLeft: '7px' }}>Gallery</span></NavLink>
          <ul className="dropdown-content"></ul>
        </li>
        <li><NavLink to="/Career" activeClassName="active" onClick={handleLinkClick}><LineChartOutlined /><span style={{ marginLeft: '7px' }}> Career</span></NavLink></li>
        <li className="dropdown">
          <NavLink to="/Contact" activeClassName="active" onClick={handleLinkClick}><FaPhoneAlt /><span style={{ marginLeft: '7px' }}>Contact Us</span></NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
