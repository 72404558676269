import React, { useEffect } from 'react';
import { Card } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import '../Component/Gallerysection.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import team from '../Component/Images/team.png';
import Training from '../Component/Images/Training.png';
import innovative from '../Component/Images/innovative.png';
import cutting from '../Component/Images/cutting.png';
import exploregallery from '../Component/Images/corporate.webp';

const { Meta } = Card;

const galleryData = [
  {
    id: 1,
    src: team,
    alt: "Our Team",
    description: "Meet the talented individuals behind Yashvitech IT Solutions who drive our success and innovation.",
  },
  {
    id: 2,
    src: Training,
    alt: "Expert Training Sessions",
    description: "Discover our hands-on training programs designed to enhance skills and knowledge in emerging tech fields.",
  },
  {
    id: 3,
    src: innovative,
    alt: "Innovative Solutions",
    description: "Showcasing our latest projects and cutting-edge technology solutions designed to drive business success and efficiency.",
  },
  {
    id: 4,
    src: cutting,
    alt: "Cutting-Edge Technology",
    description: "Explore our state-of-the-art technology solutions that push the boundaries of innovation and efficiency.",
  },
];

const GallerySection = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <>
      {/* <div className="gallery-wrapper">
        <div className="header-container" data-aos="fade" data-aos-delay="2000"><br />
          <h1>Explore Our Innovations</h1><br />
          <p>Experience the best of Yashvitech IT Solutions through our team, training, and cutting-edge technology.</p><br />
        </div>
        <div className="image-gallery">
          {galleryData.map((item, index) => (
            <Card
              key={item.id}
              cover={<img alt={item.alt} src={item.src} />}
              className="gallery-card"
              data-aos="fade"
              data-aos-delay={`${2000 + index * 100}`} // Applying staggered delay
            >
              <Meta title={item.alt} description={item.description} />
            </Card>
          ))}
        </div>
      </div> */}

      <section className="gallery-container" data-aos="fade" data-aos-delay="2000">
        <div className="text-content">
          <h1>Explore Our Gallery</h1>
          <p>
            Welcome to the vibrant world of Yashvitech IT Solutions! Here, we don’t just train; we create unforgettable experiences that spark innovation and ignite passion. Our gallery is a treasure trove of moments captured during our electrifying training sessions and events. Get ready to dive into a visual feast!
          </p>
          <p>
            From the intense focus of our coding boot camps to the joyous celebrations of our successful projects, each image tells a story of growth, learning, and a sprinkle of fun. We believe in making learning not just effective but also enjoyable. So, buckle up and enjoy the ride through our gallery!
          </p>
          <p>
            And remember, every picture is worth a thousand words; let our experiences speak for themselves.
          </p>
        </div>
        <div className="image-content" data-aos="fade" data-aos-delay="2000">
          <img src={exploregallery} alt="Gallery" style={{height: '400px'}}/>
        </div>
      </section>

      {/* <div className="gallery-section" data-aos="fade" data-aos-delay="1000">
        <div className="gallery-content">
           <h1>Gallery Extravaganza</h1>
          <p>Dive into the vibrant world of Yashvitech's achievements and events!</p> 
          
          <div className="explore-icons">
            <PictureOutlined className="explore-icon" />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default GallerySection;
