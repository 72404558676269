import React, { useEffect } from 'react';
import '../Component/Carrer3.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Carrer3 = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
    });
  }, []);

  return (
    <div className="carrer3-mainop_div">
      <div className="carrer3-opportunities-container">
        <h1 data-aos="fade-down" data-aos-delay="3000">Work With Us</h1>
        <div className="carrer3-opportunity" data-aos="fade-right" data-aos-delay="200">
          <div className="carrer3-opportunity-content">
            <h2>Explore Opportunities <span>01</span></h2>
            <p>Browse through our current job openings in React development to find the perfect fit for your skills and experience.</p>
          </div>
        </div>
        <div className="carrer3-opportunity carrer3-opportunity-alt" data-aos="fade-left" data-aos-delay="400">
          <div className="carrer3-opportunity-content">
            <h2>Submit Your Application <span>02</span></h2>
            <p>Submit your resume and cover letter through our online application portal for the position you are interested in.</p>
          </div>
        </div>
        <div className="carrer3-opportunity" data-aos="fade-right" data-aos-delay="600">
          <div className="carrer3-opportunity-content">
            <h2>Interview Process <span>03</span></h2>
            <p>If your application is shortlisted, you will be contacted for interviews to further discuss your qualifications and fit for the role.</p>
          </div>
        </div>
        <div className="carrer3-opportunity carrer3-opportunity-alt" data-aos="fade-left" data-aos-delay="800">
          <div className="carrer3-opportunity-content">
            <h2>Join Our Team <span>04</span></h2>
            <p>Congratulations! Upon successfully completing the interview process, you will receive an offer to join our team as a React developer.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrer3;
