import React, { useEffect, useState ,} from 'react';
import './Gallery1.css';
import { Button, Row, Col, Image } from 'antd';
import { VideoCameraOutlined, PictureOutlined, CalendarOutlined } from '@ant-design/icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from './Images/Gallery/Certification/1.png'
import img2 from './Images/Gallery/Certification/2.png'
import img3 from './Images/Gallery/Certification/3.png'
import img4 from './Images/Gallery/Certification/4.png'
import img5 from './Images/Gallery/Certification/5.png'
import img6 from './Images/Gallery/Certification/6.png'
import img7 from './Images/Gallery/Certification/7.png'
import img8 from './Images/Gallery/Certification/8.png'
import img9 from './Images/Gallery/Certification/9.png'
import img10 from './Images/Gallery/Certification/10.png'
import img11 from './Images/Gallery/Certification/11.png'
import img12 from './Images/Gallery/Certification/12.png'
import img13 from './Images/Gallery/Certification/13.png'
import img14 from './Images/Gallery/Certification/14.png'
import img15 from './Images/Gallery/Certification/15.png'
import img16 from './Images/Gallery/Certification/16.png'
import img17 from './Images/Gallery/Certification/17.png'
import img18 from './Images/Gallery/Certification/18.png'
import img19 from './Images/Gallery/Certification/19.png'
import img20 from './Images/Gallery/Certification/20.png'
import img21 from './Images/Gallery/Certification/21.png'
import img22 from './Images/Gallery/Certification/22.png'
import img23 from './Images/Gallery/Certification/23.png'
import img24 from './Images/Gallery/Events/1.jpg'
import img25 from './Images/Gallery/Events/2.jpeg'
import img26 from './Images/Gallery/Events/3.jpeg'
import img27 from './Images/Gallery/Events/4.jpeg'
import img28 from './Images/Gallery/Events/5.jpeg'
import img29 from './Images/Gallery/Events/6.jpeg'
import img30 from './Images/Gallery/Events/7.jpeg'
import img31 from './Images/Gallery/Events/8.jpg'
import img32 from './Images/Gallery/Events/9.jpg'
import img33 from './Images/Gallery/Events/10.jpg'
const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []); // Trigger effect on pathname change
  
  const [filter, setFilter] = useState('all');
  const images = [
    { src: img1, type: 'certification' },
    { src: img19, type: 'certification' },
    { src: img20, type: 'certification' },
    { src: img7, type: 'certification' },
    { src: img2, type: 'certification' },
    { src: img3, type: 'certification' },
    { src: img4, type: 'certification' },
    { src: img5, type: 'certification' },
    { src: img6, type: 'certification' },
    { src: img8, type: 'certification' },
    { src: img9, type: 'certification' },
    { src: img10, type: 'certification' },
    { src: img11, type: 'certification' },
    { src: img12, type: 'certification' },
    { src: img13, type: 'certification' },
    { src: img14, type: 'certification' },
    { src: img15, type: 'certification' },
    { src: img16, type: 'certification' },
    { src: img17, type: 'certification' },
    { src: img18, type: 'certification' },
    { src: img21, type: 'certification' },
    { src: img22, type: 'certification' },
    { src: img23, type: 'certification' },
    
    { src: img24, type: 'event' },
    { src: img25, type: 'event' },
    { src: img27, type: 'event' },
    { src: img28, type: 'event' },
    { src: img29, type: 'event' },
    { src: img31, type: 'event' },
    { src: img32, type: 'event' },
    { src: img33, type: 'event' },
    { src: img26, type: 'event' },
    { src: img30, type: 'event' },
  ];

  useEffect(() => {
    AOS.init({
      duration: 500, // Reduced duration
      delay: 500, // Reduced delay
    });
  }, []);

  const filteredImages = images.filter((image) => filter === 'all' || image.type === filter);

  return (
    <div className="gallery-containers">
      <br />
      <br />
      <div className="mt-5" justify="center" align="middle">
        <Row>
          <Col span={24}>
            <Button.Group className="button-group" data-aos="fade-up" data-aos-delay="500">
              {/* <Button onClick={() => setFilter('all')}>All</Button> */}
              <Button onClick={() => setFilter('event')}>
                <CalendarOutlined /> Event
              </Button>
              <Button onClick={() => setFilter('certification')}>
                <PictureOutlined /> Certification
              </Button>

            </Button.Group>
          </Col>
        </Row>

        <br />
        <br />
        <Row className="mt-3" gutter={[16, 16]}>
          {filteredImages.map((image) => (
            <Col
              className='gallery_col'
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <Image src={image.src} alt="Gallery Image" />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Gallery;