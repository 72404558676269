import React from 'react';
import './Footer.css';
import { FaPhoneAlt } from 'react-icons/fa';
import { FloatingWhatsApp } from 'react-floating-whatsapp2';
import logo from './Images/yashvitech_logo.jpg';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer-container">

            <div className="footer">
                <div className="footer-section">
                    <h4 style={{ color: "gold" }}>YASHVITECH IT SOLUTION</h4>
                    <ul>
                        <li><i></i> A software solution provider
                            company with a view to digitise the day to day activities of the
                            organisations varying from all the major sectors of business houses.</li>

                    </ul>
                </div>
                <div className="footer-section">
                    <h4 style={{ color: "gold" }}>QUICK LINKS</h4>
                    <ul>
                        {/* <li><a href="/"><i className="fas fa-home"></i> Home Page</a></li> */}
                        <li> <Link to="/about"><i className="fas fa-building"></i> About</Link></li>
                        <li><Link to="/Contact"><i className="fas fa-envelope"></i> Contact</Link></li>
                        <li><Link to="/Portfolio"><i className="fas fa-briefcase"></i> Portfolio</Link></li>
                        {/* <li><Link to="/Clients"><i className="fas fa-envelope"></i> Terms and conditions</Link></li> */}
                        {/* <li><a href="/Enquiry"><i className="fas fa-box"></i> Privacy policy</a></li> */}

                    </ul>
                </div>
                <div className="footer-section">
                    <h4 style={{ color: "gold" }}>OUR SERVICES</h4>
                    <ul>
                        <h1 className='ios'><li><i className="fas fa-paint-brush"></i> Website Development</li></h1>
                        <h1 className='ios'><li><i className="fas fa-code"></i> Mobile App Development</li></h1>
                        <h1 className='ios'><li><i className="fas fa-cogs"></i> Software Training & Internship</li></h1>
                        <h1 className='ios'><li><i className="fas fa-bullhorn"></i> Digital Markting & Services</li></h1>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4 style={{ color: "gold" }}>YASHVITECH IT SOLUTION</h4>
                    <p>
                        <i className="fas fa-envelope"></i> info@yashvitech.com
                        {/* <i className="fas fa-map-marker-alt"></i> Yashvitech IT Solution */}
                        <br />
                        <a href="tel:${8640091593}" style={{ color: "white", textDecoration: 'none' }}><FaPhoneAlt />&nbsp;+91 8640091593</a><br></br>

                        <i className="fas fa-map-marker-alt"></i>2nd Floor, Sahu Complex, near Disha College, Ramnagar, Kota, Raipur, Chhattisgarh 492001<br />

                    </p>
                </div>
                <FloatingWhatsApp avatar={logo} accountName='Yashvitech IT Solution ' notificationSound={true} phoneNumber='918640091593' />
            </div>
            <div className="footer-copyright">
                <p  style={{ backgroundColor: "gold",color:"black" ,fontSize:"15px"}}>Copyright © Yashvitech IT Solution 2024</p>
            </div>
        </div>
    );
}

export default Footer;
