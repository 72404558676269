
import React from 'react';
import { Row, Col, Button } from 'antd';
import './Training_Internship.css';
import Course from './Course_overview';

const ImproveSkills = () => {
  return (
    <div>
      <div className="improve-skills-container">
        <div className="overlay"></div>
        <div className="improve-skills-header">
          <h2 className="sub-title">INTERNSHIP FOR ALL IT/CS STUDENT</h2>
          <h1 className="main-title">IMPROVE YOUR SKILLS</h1>
          <h3 className="description">
            Training and development is critical for any professional. It helps you improve your performance and helps your organization meet its business goals. Building new skills makes an individual more efficient at a job or capable of handling different responsibilities and challenges.
            <br />
            A professional who receives the necessary training is better able to perform one’s job. The training strengthens your confidence because you have a deeper understanding of your role and responsibilities to perform even better and think of new ideas that help you excel.
          </h3>
        </div>
        <Row gutter={[16, 16]} className="button-row">
          <Col xs={24} sm={8} className="button-col">
           <a href='/about'><Button className="info-button" type="primary" size="large">
              ABOUT US
            </Button></a> 
          </Col>
          <Col xs={24} sm={8} className="button-col">
            <a href='/Fullstack'><Button className="info-button" type="primary" size="large">
              ALL COURSES
            </Button></a>
          </Col>
          <Col xs={24} sm={8} className="button-col">
           <a href='/Contact'> <Button className="info-button" type="primary" size="large">
              ENQUIRY NOW!
            </Button></a>
          </Col>
        </Row>
      </div>
      <Course />
    </div>
  );
};

export default ImproveSkills;
