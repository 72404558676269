

import React from 'react';
import './Fullstack.css';
import img1 from '../Component/Images/seo.webp';
import img2 from '../Component/Images/socialmedia.webp';
import img3 from '../Component/Images/EM1.gif';
import img4 from '../Component/Images/affranking.gif';
import img5 from '../Component/Images/pyth.gif';
import img6 from '../Component/Images/dj.jpeg';
import img7 from '../Component/Images/php2.gif';
import img8 from '../Component/Images/db.gif';

const Card = ({ title, description, imgSrc }) => {
  return (
    <div className="card">
      <div className="card-img-container">
      <img src={imgSrc} alt={title} className="card-img" />
      </div>
      <div className="card-content">
        <h2>{title}</h2>
        <p>{description}</p>
        <a href="/Enquiry"> <button className="card-button">Request A Demo</button></a>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <div className="card-container">
      <Card
        title="SEO"
        description="Search engine optimization (SEO) is the process of improving the quality and quantity of website traffic to a website or a web page from search engines.SEO targets unpaid traffic rather than direct traffic or paid traffic. Unpaid traffic may originate from different kinds of searches, including image search, video search, academic search,news search, and industry-specific vertical search engines."
        imgSrc={img1}
      />
      <Card
        title="Social Media"
        description="Social media is an internet-based form of communication. Social media platforms allow users to have conversations, share information and create web content.The Office of Communications and Marketing manages the main Facebook, X/Twitter, Instagram, LinkedIn, and YouTube accounts.Social media is the communal interaction among people in which they create, share or exchange information and ideas in virtual communities."
        imgSrc={img2}
      />
      <Card
        title="Email Marketing"
        description="Email marketing is a form of direct marketing that uses messages to promote products or services, send thought leadership pieces, or connect with their audience in other ways. A thoughtful email marketing strategy provides customers with interesting and valuable information to make smarter purchasing decisions.Email marketing is the act of sending a commercial message, typically to a group of people, using email. "
        imgSrc={img3}
      />
      <Card
        title="Affiliate Marketing"
        description="Affiliate marketing is a marketing arrangement in which affiliates receive a commission for each visit, signup or sale they generate for a merchant. This arrangement allows businesses to outsource part of the sales process.It is a form of performance-based marketing where the commission acts as an incentive for the affiliate; this commission is usually a percentage of the price of the product being sold, but can also be a flat rate per referral."
        imgSrc={img4}
      />
      {/* <Card
        title="Python"
        description="Python is a versatile and powerful programming language that has made a significant impact across various domains, from web development and data science to artificial intelligence and scientific research. Its simplicity, readability, and extensive library support continue to attract both beginners and experienced programmers, making it a vital tool in the world of technology and software development. Python consistently ranks as one of the most popular programming languages."
        imgSrc={img5}
      />
      <Card
        title="Django"
        description="Django is a powerful and versatile web framework that simplifies web application development while promoting best practices in security, scalability, and maintainability. Its comprehensive feature set, active community, and commitment to developer productivity have made it a go-to choice for building a wide range of web applications across various industries. Django takes security seriously and helps developers avoid many common security mistakes."
        imgSrc={img6}
      /> */}
      <Card
        title="PHP"
        description="PHP, which stands for Hypertext Preprocessor is a widely used server-side scripting language for web development. It is known for its versatility, ease of use, and integration capabilities, making it one of the most popular choices for building dynamic web applications. In this article, we will explore PHP in more detail, covering its history, key features, common use cases, and its role in modern web development. PHP/FI was the first version to be publicly released in 1995."
        imgSrc={img7}
      />
      {/* <Card
        title="Database"
        description="A database is a structured collection of data that is organized and stored for easy retrieval, management, and manipulation. Databases are fundamental to the field of information technology and play a critical role in various aspects of our daily lives, from managing business operations to powering social media platforms and online shopping websites. The database system or an application associated with the database."
        imgSrc={img8}
      /> */}
    </div>
  );
};

export default App;
