import React from 'react';
import './Frontend.css';
import img1 from '../Component/Images/html1.gif';
import img2 from '../Component/Images/css1.gif';
import img3 from '../Component/Images/js.gif';
import img4 from '../Component/Images/react3.gif';
import img5 from '../Component/Images/Angular (1).gif';
import img6 from '../Component/Images/dj.jpeg';
import img7 from '../Component/Images/php2.gif';
import img8 from '../Component/Images/db.gif';

const Card = ({ title, description, imgSrc }) => {
  return (
    <div className="card">
      <div className="card-img-container" >
      <img src={imgSrc} alt={title} className="card-img" />
      </div>
      <div className="card-content">
        <h2>{title}</h2>
        <p>{description}</p>
        <a href="/Enquiry"><button className="card-button">Request A Demo</button></a>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <div className="card-container">
      <Card
        title="HTML"
        description="HTML is the backbone of the World Wide Web, providing the structure and semantics necessary to create web pages. HTML elements are delineated by tags, written using angle brackets. It has evolved over the years to support multimedia, interactivity, and improved accessibility. As web technologies continue to advance, HTML will likely remain a fundamental tool for web developers, shaping the digital landscape for years to come."
        imgSrc={img1}
      />
      <Card
        title="CSS"
        description="CSS is an essential technology for web development, allowing developers to control the visual presentation of HTML documents. With a good understanding of CSS selectors, properties, and layout techniques, web designers can create visually appealing and responsive websites that cater to a wide range of devices and screen sizes. As web development continues to evolve, CSS will remain a vital skill for creating engaging and user-friendly web experiences."
        imgSrc={img2}
      />
      <Card
        title="JavaScript"
        description="JavaScript is a foundational technology in web development, empowering developers to build interactive and responsive web applications. Its continued evolution through standards like ECMAScript ensures its relevance and adaptability in the ever-changing landscape of web development. Whether you're a front-end developer, a full-stack engineer, or working on other web-related tasks, JavaScript is a valuable tool in your toolkit."
        imgSrc={img3}
      />
      <Card
        title="React.js"
        description="React.js has become a cornerstone of modern web development due to its component-based architecture, efficient rendering with the Virtual DOM, and strong developer ecosystem. As the web development landscape continues to evolve, React remains a popular and versatile choice for building dynamic and responsive user interfaces for web and mobile applications. You don’t have to build your whole page in React. Add React to your existing HTML page, and render interactive React components anywhere on it."
        imgSrc={img4}
      />
      <Card
        title="Angular"
        description="Angular is a TypeScript-based free and open-source single-page web application framework. AngularJS is a JavaScript framework. It can be added to an HTML page with a <script> tag. AngularJS extends HTML attributes with Directives, and binds data to HTML with Expressions."
        imgSrc={img5}
      />
      {/* <Card
        title="Django"
        description="Django is a powerful and versatile web framework that simplifies web application development while promoting best practices in security, scalability, and maintainability. Its comprehensive feature set, active community, and commitment to developer productivity have made it a go-to choice for building a wide range of web applications across various industries. Django takes security seriously and helps developers avoid many common security mistakes."
        imgSrc={img6}
      />
      <Card
        title="PHP"
        description="PHP, which stands for Hypertext Preprocessor is a widely used server-side scripting language for web development. It is known for its versatility, ease of use, and integration capabilities, making it one of the most popular choices for building dynamic web applications. In this article, we will explore PHP in more detail, covering its history, key features, common use cases, and its role in modern web development. PHP/FI was the first version to be publicly released in 1995."
        imgSrc={img7}
      />
      <Card
        title="Database"
        description="A database is a structured collection of data that is organized and stored for easy retrieval, management, and manipulation. Databases are fundamental to the field of information technology and play a critical role in various aspects of our daily lives, from managing business operations to powering social media platforms and online shopping websites. The database system or an application associated with the database."
        imgSrc={img8}
      /> */}
    </div>
  );
};

export default App;