



import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './AboutUs.css';
import thecompany from './Images/the-company.jpg';
import ourmission from './Images/ourmission.jpg';
import ourvision from './Images/ourvision.jpg';
import ourteam from './Images/ourteam.jpg';

const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []); // Trigger effect on pathname change
  

  return (
    <div className='about-us-main'>
      {/* ..............................The Company...................... */}
      <div className="The-company-container" data-aos="fade-up">
        <div className="The-company-background">
          <div className="The-company-content">
            <div className="The-company-image-container" data-aos="slide-right">
              <img src={thecompany} alt="The Company" className="The-company-image" />
            </div>
            <div className="The-company-content-container" data-aos="slide-left">
              <h2 className='heading'>The Company</h2>
              <p id='paragraph'>
              <b>Yashvitech IT Solution </b> is architects of our digital world, responsible for creating the software applications, IT services, and technologies that define modern life. Their functions span diverse industries, their impact reaches every corner of society, and their innovations continually shape the future. As they navigate the ever-evolving technological landscape, their commitment to innovation, excellence, and ethical practices ensures that they remain at the forefront of driving progress and transforming possibilities into realities.
              </p>
              <br />
              <ul style={{paddingLeft:"15px"}} id='paragraph'>
                <li>Quality, innovation, results.</li>
                <li>Passionate collaboration.</li>
                <li>Social responsibility.</li>
                <li>Ongoing learning and development.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* ..............................Our Mission...................... */}
      <div className="Our-mission-container" data-aos="fade-up">
        <div className="Our-mission-background">
          <div className="Our-mission-content">
            <div className="Our-mission-content-container" data-aos="slide-right">
              <h2 className='heading'>Our Mission</h2>
              <p id='paragraph'>
                At  <b>Yashvitech IT Solution </b> our mission is to harness the power of technology to drive innovation, empower businesses, and enrich lives. We are dedicated to delivering exceptional software solutions and IT services that transform challenges into opportunities, inspire growth, and positively impact our clients, employees, and the communities we serve.
              </p>
              <br/>
              <ul style={{paddingLeft:"15px"}} id='paragraph'>
                <li>Empowering success through exceptional services.</li>
                <li>Collaborative approach for customized solutions.</li>
                <li>Focus on quality, results, and customer satisfaction.</li>
                <li>Operating with integrity and social responsibility.</li>
              </ul>
            </div>
            <div className="Our-mission-image-container" data-aos="slide-left">
              <img src={ourmission} alt="Our Mission" className="Our-mission-image" />
            </div>
          </div>
        </div>
      </div>

      {/* ..............................Our Vision...................... */}
      <div className="The-company-container" data-aos="fade-up">
        <div className="The-company-background">
          <div className="The-company-content">
            <div className="The-company-image-container" data-aos="slide-right">
              <img src={ourvision} alt="Our Vision" className="The-company-image" />
            </div>
            <div className="The-company-content-container" data-aos="slide-left">
              <h2 className='heading'>Our Vision</h2>
              <p id='paragraph'>
                At <b>Yashvitech IT Solution </b>our vision is to be at the forefront of shaping a digital future where technology transforms lives, businesses, and societies. We are driven by the belief that innovation has the power to create positive change and drive progress in a rapidly evolving world.
              </p>
              <br/>
              <ul style={{paddingLeft:"15px"}} id='paragraph'>
                <li>Quality, innovation, results.</li>
                <li>Passionate collaboration.</li>
                <li>Social responsibility.</li>
                <li>Ongoing learning and development.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* ..............................Our Team...................... */}
      <div className="Our-mission-container" data-aos="fade-up">
        <div className="Our-mission-background">
          <div className="Our-mission-content">
            <div className="Our-mission-content-container" data-aos="slide-right">
              <h2 className='heading'>Our Team</h2>
              <p id='paragraph'>
                At  <b>Yashvitech IT Solution </b>, our team is not just a group of individuals; we are a family united by our shared commitment to innovation, excellence, and client success. Together, we are dedicated to helping our clients navigate the ever-evolving digital landscape with state-of-the-art solutions tailored to their unique needs.
              </p>
              <br/>
              <p id='paragraph'>
                Our success is not solely measured by our achievements but by the collective efforts of each team member, working tirelessly to turn challenges into opportunities and ideas into reality. We're not just in the business of technology; we're in the business of transforming possibilities into realities.
              </p>
              <br/>
              <p id='paragraph'>
                We invite you to get to know our team personally, as their passion and expertise are the driving forces that make  <b>Yashvitech IT Solution </b> a leader in the industry. Stay connected with us as we continue to innovate, pushing the boundaries of technology to bring you tomorrow's solutions today.
              </p>
            </div>
            <div className="Our-mission-image-container" data-aos="slide-left">
              <img src={ourteam} alt="Our Team" className="Our-mission-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;








