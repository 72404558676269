import React from 'react'
import Home from './Home'
import Ourtechnology from './Ourtechnology'
import Video from '../Component/Video'
import Perks from './Perks'
import Ourservice from './Service'


function Landing_page() {
  return (
    <div>
      <Home/>
      <Ourservice/>
      {/* <Video/> */}
      <Perks/><br></br><br></br>
      {/* <Ourtechnology/><br></br><br></br> */}
      
    </div>
  )
}

export default Landing_page
