import React ,{useEffect} from 'react';
import './Course_overview.css';
import img1 from '../Component/Images/Full-Stack.jpg';
import img2 from '../Component/Images/frontend.png';
import img3 from '../Component/Images/backend1.png';
import img4 from '../Component/Images/marketing.png';

const courses = [
    {
        title: 'Full Stack Developer Course',
        imageUrl: img1,
        demoLink: '/Fullstack'
    },
    {
        title: 'Frontend Developer',
        imageUrl: img2,
        demoLink: '/Frontend'
    },
    {
        title: 'Backend Developer',
        imageUrl: img3,
        demoLink: '/Backend'
    },
    {
        title: 'Digital Marketing',
        imageUrl: img4,
        demoLink: '/Digital_Marketing'
    }
];

const ImageGallery = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top
      }, []); // Trigger effect on pathname change
      
    return (
        <div className="gallery">
            {courses.map((course, index) => (
                <div className="card" key={index}>
                    <img src={course.imageUrl} alt={course.title} />
                    <div className="card-content">
                        {/* <h3>{course.title}</h3>h */}
                        <a href={course.demoLink} className="demo-button">Click Here For Detail</a>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ImageGallery;
