




import React,{useEffect} from 'react'
import Career from '../Component/Carrer'
import Icongrid from '../Component/IconGrid'



function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []); // Trigger effect on pathname change
  
  return (
    <div>
      <Career/>
      <Icongrid/>
      
    </div>
  )
}

export default Careers