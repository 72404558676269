import React from 'react'
import './Perks.css';
import image from '../Component/Images/career.jpg';
import Client1 from './Images/htm.png';
import Client2 from './Images/css3.png';
import Client3 from './Images/javas.jpg';
import Client4 from './Images/py.png';
import Client5 from './Images/django.jpg';
import Client6 from './Images/flask.png';
import Client7 from './Images/reac.png';
import Client8 from './Images/native.jpeg';
import Client9 from './Images/NJS.png';
import Client10 from './Images/AJS.png';
import Client11 from './Images/javai.png';
import Client12 from './Images/php.png';
import Client13 from './Images/laravel.png';
import Client14 from './Images/mysql.png';
import Client15 from './Images/postsql.png';
import Client16 from './Images/mongo.png';
import Client17 from './Images/github.png';
import Client18 from './Images/sqlite1.png';
const Perks = () => {
  const perks = [
    { icon: Client1, title: 'HTML' },
    { icon: Client2, title: "CSS" },
    { icon: Client3, title: 'JavaScript' },
    { icon: Client4, title: 'Python' },
    { icon: Client5, title: 'Django' },
    { icon: Client6, title: 'Flask' },
    { icon: Client7, title: 'React JS' },
    { icon: Client8, title: 'React Native' },
    { icon: Client9, title: 'Node JS' },
    { icon: Client10, title: "Angular JS" },
    { icon: Client11, title: 'Java' },
    { icon: Client12, title: 'PHP' },
    { icon: Client13, title: 'Laravel' },
    { icon: Client17, title: 'GitHub' },
    { icon: Client14, title: 'MySQL' },
    { icon: Client15, title: 'PostgreSQL' },
    { icon: Client16, title: 'MongoDB' },
    { icon: Client18, title: 'SQLite' },
  ];

  return (
    <div>
      <div className="background-image" style={{ backgroundImage: `url(${image})` }}></div>
      <div className="perks-container">
        <h2 className="perks-title">OUR TECHNOLOGIES</h2><br/>
        <div className="perks-grid">
          {perks.map((perk, index) => (
            <div key={index} className="perk-item">
              <div className="button"><img src={perk.icon} style={{height:'70px', width:'70px', borderRadius:'40px'}}/></div><br/>
              <div className="perk-title">{perk.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Perks;