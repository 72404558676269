import React from 'react'
import videoSrc from './Images/Videos.mp4'
import './Video.css'
function Video() {
  return (
    <div>
        <video className="banner-video" autoPlay muted loop>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
    </div>
  )
}

export default Video