

import React, { useEffect } from 'react';
import { Card } from 'antd';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Service.css';
import serviceimg1 from '../Component/Images/website.png';
 import serviceimg2 from '../Component/Images/software_training.jpg';
 import serviceimg3 from '../Component/Images/mobile.png';
 import serviceimg4 from '../Component/Images/softmaintenance.png';
 import serviceimg5 from '../Component/Images/api.png';
 import serviceimg6 from '../Component/Images/digital1.png';

const featuresData = [
  {
    title: 'Website Development',
    description: 'We specialize in custom software solutions using Python, React, and PHP to meet unique business needs, enhancing efficiency and productivity.',
    imgSrc: serviceimg1,
    animation: 'fade-left'
  },
  {
    title: 'Software Training & Internship',
    description: 'We offer hands-on training in Python, React, PHP, and other key technologies to nurture talent and enhance skills in the dynamic IT landscape, preparing individuals to excel in the industry.',
    imgSrc: serviceimg2,
    animation: 'fade-up'
  },
  {
    title: 'Mobile App Development',
    description: 'YASHVITECH crafts custom mobile applications for iOS and Android using Java, Kotlin, Swift, and React Native to perfectly align with your unique requirements.',
    imgSrc: serviceimg3,
    animation: 'fade-right'
  },
  {
    title: 'API Development',
    description: 'Choose YASHVITECH for software maintenance and support, ensuring your applications stay secure and user-friendly while you focus on growing your business.',
    imgSrc: serviceimg6,
    animation: 'fade-down'
  },
  {
    title: 'Software Maintenance and Support',
    description: 'At YASHVITECH, we offer expert API building services to seamlessly integrate and communicate data, thoughtfully crafted to suit your specific needs.',
    imgSrc: serviceimg4,
    animation: 'fade-down'
  },
  {
    title: 'Digital Marketing and Services',
    description: 'At YASHVITECH, we use our digital expertise to boost brand visibility, engage audiences, and drive conversions with strategies that enhance online presence, generate leads, and foster loyalty.',
    imgSrc: serviceimg5,
    animation: 'fade-right'
  }
];

const Service = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false, // Set to false to animate each time you scroll
    });

    // Re-initialize AOS on component update
    AOS.refresh();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []); // Trigger effect on pathname change
  
  return (
    <div className="features-container">
      {/* <h2 className="features-title" data-aos="fade-down">FEATURES</h2> */}
      <h3 className="features-subtitle" data-aos="fade-left">Our Features & Services</h3>
      <div className="features-grid">
        {featuresData.map((feature, index) => (
          <Card 
            key={index} 
            className="feature-card"
            data-aos={feature.animation}
          >
            <img src={feature.imgSrc} alt={feature.title} className="feature-image" />
            <h4 className="feature-title">{feature.title}</h4>
            <p className="feature-description">{feature.description}</p>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Service;