import React, { useEffect } from 'react';
import '../Component/IconGrid.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Carrer2 from '../Component/Carrer2';
import Carrer1 from '../Component/Carrer1';
import Carrer3 from '../Component/Carrer3';
import {
  FaWrench as FaWrenchOutlined,
  FaRobot as FaRobotOutlined,
  FaLock as FaLockOutlined,
  FaDesktop as FaDesktopOutlined,
  FaGlobe as FaGlobeOutlined,
  FaLightbulb as FaLightbulbOutlined,
  FaHdd as FaHddOutlined,
  FaUserShield as FaUserShieldOutlined,
  FaNetworkWired as FaNetworkWiredOutlined,
} from 'react-icons/fa';
import { IoServerSharp } from "react-icons/io5";

const items = [
  {
    description: 'Enhance your infrastructure with our cutting-edge technology solutions.',
    icon: IoServerSharp,
    animation: 'fade-up',
    delay: 0
  },
  {
    description: 'Our team members are highly skilled professionals ready to assist you.',
    icon: FaRobotOutlined,
    animation: 'fade-down',
    delay: 500
  },
  {
    description: 'We provide top-notch security management for your business.',
    icon: FaLockOutlined,
    animation: 'fade-left',
    delay: 1000
  },
  {
    description: 'Optimize your desktop computing with our expert services.',
    icon: FaDesktopOutlined,
    animation: 'fade-right',
    delay: 1500
  },
  {
    description: 'Stay ahead with our advanced information technology services.',
    icon: FaGlobeOutlined,
    animation: 'fade-up',
    delay: 1000
  },
  {
    description: 'Get ideas for high return investments with our innovative solutions.',
    icon: FaLightbulbOutlined,
    animation: 'fade-down',
    delay: 1000
  },
  {
    description: 'Experience fully managed IT services with our comprehensive solutions.',
    icon: FaHddOutlined,
    animation: 'fade-left',
    delay: 1000
  },
  {
    description: 'Ensure data security and management with our trusted services.',
    icon: FaUserShieldOutlined,
    animation: 'fade-right',
    delay: 1000
  }
];

const IconGrid = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <div className="icon-grid-container">
        <h1 className="icon-grid-why-choose-us-title" data-aos="fade-down" data-aos-delay="3000">Why Choose Us ?</h1>
        <div className="icon-grid-grid">
          {items.map((item, index) => (
            <div
              className="icon-grid-icon-item"
              data-aos={item.animation}
              data-aos-delay={item.delay}
              key={index}
            >
              <div className="icon-grid-icon">
                <item.icon data-aos="pulse" data-aos-duration="2000" />
              </div>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
      <Carrer1 />
      <Carrer2 />
      <Carrer3 />
    </>
  );
};

export default IconGrid;
