
// export default Enquiry;
import React ,{useEffect}from 'react';
import { Form, Input, Button, message } from 'antd';
import emailjs from 'emailjs-com';
import './Enquiry.css';
// import Contactimg from '../Image/Contact.jpg';
// import Contactimg from '../Component/Images/';
import Contactimg from '../Component/Images/Contact.jpg'

const { TextArea } = Input;

const Enquiry = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top
      }, []); // Trigger effect on pathname change
      
    const [form] = Form.useForm();

    const sendEmail = (values) => {
        console.log('Sending email with values:', values); // Debugging statement
        emailjs.send('service_10bivt8', 'template_ndtsf1z', values, 'x1CSpE8Vmw0elYLmk')
            .then((result) => {
                console.log('Email successfully sent!', result.text);
                message.success('Enquiry sent successfully!');
                form.resetFields(); // Reset form fields after successful submission
            }, (error) => {
                console.error('Failed to send email:', error);
                message.error(`Failed to send enquiry: ${error.text || error.message}`);
            });
    };
 
    const onFinish = (values) => {
        console.log('Form values:', values); // Debugging statement
        sendEmail(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Form submission failed:', errorInfo); // Debugging statement
    };
 
    return (
        <>
           
            <div className="main-div">
                <div className="mainflex" style={{ display: "flex", columnGap: "50px" }}>
                    <div>
                        <img src={Contactimg} alt="" />
                    </div>
                    <div className="form-container">
                        <Form
                            form={form}
                            name="userForm"
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please input your name!' }]}
                            >
                                <Input placeholder="Enter your name" />
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    { required: true, message: 'Please input your email!' },
                                    { type: 'email', message: 'Please enter a valid email!' }
                                ]}
                            >
                                <Input placeholder="Enter your email" />
                            </Form.Item>
                            <Form.Item
                                label="Phone Number"
                                name="mobileNumber"
                                rules={[{ required: true, message: 'Please input your phone number!' }]}
                            >
                                <Input placeholder="Enter your phone number" />
                            </Form.Item>
                            <Form.Item
                                label="Description"
                                name="message"
                                rules={[{ required: true, message: 'Please input your description!' }]}
                            >
                                <TextArea placeholder="Enter your description" rows={4} />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className='btn_color'>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Enquiry;