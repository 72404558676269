

// import React from 'react';
// import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from 'react-icons/fa';
// import './SocialMediaLinks.css';

// const SocialSidebar = () => {
//     return (
//         <>
//             <div className="social-sidebar">
//                 <a href="https://www.facebook.com/people/Yashvitech-Raipur/pfbid02MDYkweWUL54cUGVi1KWeCZJrKfKxRNQ8zdkWoRCLEUnnt5fmf4bxHDGjfD7cUR9Cl/" className="social-icon facebook">
//                     <FaFacebookF />
//                     <span>Facebook</span>
//                 </a>
//                 <a href="https://www.instagram.com/yashvitechraipur/" className="social-icon instagram">
//                     <FaInstagram />
//                     <span>Instagram</span>
//                 </a>
//                 <a href="https://www.youtube.com/@YashvitechITSolution?si=iRydMOEileM4odLQ" className="social-icon youtube">
//                     <FaYoutube />
//                     <span>YouTube</span>
//                 </a>
//                 <a href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A102230115&keywords=yashvitech%20it%20solution%20pvt%20ltd&origin=RICH_QUERY_SUGGESTION&position=0&searchId=7fafcf2c-39c0-41ba-90c2-8be49f04a66d&sid=dzD&spellCorrectionEnabled=false" className="social-icon linkedin">
//                     <FaLinkedinIn />
//                     <span>LinkedIn</span>
//                 </a>
//             </div>
            
//         </>
//     );
// }

// export default SocialSidebar;



import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from 'react-icons/fa';
import './SocialMediaLinks.css';

const SocialSidebar = () => {
    return (
        <>
            <div className="social-sidebar">
                <a 
                    href="https://www.facebook.com/people/Yashvitech-Raipur/pfbid02MDYkweWUL54cUGVi1KWeCZJrKfKxRNQ8zdkWoRCLEUnnt5fmf4bxHDGjfD7cUR9Cl/" 
                    className="social-icon facebook" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <FaFacebookF />
                    <span>Facebook</span>
                </a>
                <a 
                    href="https://www.instagram.com/yashvitechraipur/" 
                    className="social-icon instagram" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <FaInstagram />
                    <span>Instagram</span>
                </a>
                <a 
                    href="https://www.youtube.com/@YashvitechITSolution?si=iRydMOEileM4odLQ" 
                    className="social-icon youtube" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <FaYoutube />
                    <span>YouTube</span>
                </a>
                <a 
                    href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A102230115&keywords=yashvitech%20it%20solution%20pvt%20ltd&origin=RICH_QUERY_SUGGESTION&position=0&searchId=7fafcf2c-39c0-41ba-90c2-8be49f04a66d&sid=dzD&spellCorrectionEnabled=false" 
                    className="social-icon linkedin" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <FaLinkedinIn />
                    <span>LinkedIn</span>
                </a>
            </div>
        </>
    );
}

export default SocialSidebar;