import React, { useEffect } from 'react';
import '../Component/Carrer1.css';
import lappy from '../Component/Images/lappy.png';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const Carrer1 = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <div className="carrer1-App">
      <div className="carrer1-header-container" data-aos="fade-in" data-aos-delay="2000">
          <h1 className="carrer1-main-heading" data-aos="fade-in" data-aos-delay="3000">
            Explore Exciting Career Opportunities with <br />Yashvitech IT Solutions
          </h1>
          <p className="carrer1-sub-heading" data-aos="fade-in" data-aos-delay="7000">
            Discover your dream job among our diverse range of career options. Join us in shaping the future of technology.
          </p>
        </div>


        <div className="carrer1-main-container" data-aos="fade-in" data-aos-delay="4000">
          <div className="carrer1-image-container" data-aos="fade-up-right" data-aos-delay="4000">
            <img src={lappy} alt="Laptop with Map" className="carrer1-image" />
          </div>
          <div className="carrer1-line-div" data-aos="fade-in"></div>
          <div className="carrer1-text-container" data-aos="fade-in" data-aos-delay="5000">
            <h2 data-aos="fade-in" data-aos-delay="500">Collaborative Environment</h2>
            <p data-aos="fade-in" data-aos-delay="500">Join a team that values teamwork and creativity in React development</p><br />
            <h2 data-aos="fade-in" data-aos-delay="1000">Skill Development</h2>
            <p data-aos="fade-in" data-aos-delay="1000">Continuous learning and growth in React development skills</p><br />
            <h2 data-aos="fade-in" data-aos-delay="1200">Innovative Projects</h2>
            <p data-aos="fade-in" data-aos-delay="1200">Opportunities to work on cutting-edge projects using React technology</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Carrer1;
