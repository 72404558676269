// import React from 'react';
// import './Home.css'; // Import the CSS file
// // import Img from '..//Image/banner-img.jpg'
// import Img from '../Component/Images/company3.jpg';
// const HomePage = () => {
//   return (
//     <div className="home-container">
//       <div className="home-content">
//         <div className="banner-text">
//           {/* <div className="triangle"></div> */}
//           <h1>
//             {/* <span className="subheading">Best Technology Partner</span><br /> */}
//             Technologist & IT Solutions <span className="highlight">Agency </span>Of India
//           </h1>
//           {/* <p>
//            Yashvitech Is The Leading IT Solutions Company Based In Raipur, India. Since 2023,Yashvitech Is Helping Businesses Both National And Global With IT Solutions And Services To Meet The Evolving Demands Of Technology.
//           </p> */}
//           <p>
//           Yashvitech IT Solutions is an innovative IT company based in Raipur, established in 2023. We are dedicated to empowering businesses globally with cutting-edge technology solutions and services. At Yashvitech, we are committed to driving growth and efficiency for our clients, ensuring they stay ahead in the rapidly evolving digital landscape.
//           </p><br></br>
//           <button className="button">Learn More</button>
//         </div>
//         <img className="banner-image" src={Img} alt="Banner" />
//       </div>
//     </div>
//   );
// };

// export default HomePage;






// // ...................................degine 2 .....................

// // // src/components/MainContent.js
// // import React from 'react';
// // import './Home.css';
// // import Image from '../Image/bluebackground.jpg'
// // import Image1 from '../Image/Web-desingning1.png'
// // import { ImBin2 } from "react-icons/im"
// // import { FaCartShopping } from "react-icons/fa6";
// // import { FaRegLightbulb } from "react-icons/fa6";
// // import { MdOutlinePushPin } from "react-icons/md";
// // // import { Carousel } from 'antd';

// // const Home = () => {
// //     return (
// //         <main>
// //                 <div className='Home-background-div'>
// //                     <img src={Image} className='Home-background-image'></img>
// //                     <div className='home-contant'>
// //                         <div>
// //                             <img src={Image1} className='home-overlay-image'></img>
// //                         </div>
// //                         <div className="text-content">
// //                             <p className="slide-in-left">Website Designing and Software Development</p><br></br>
// //                             <ul>
// //                                 <li className="slide-in-Right"><ImBin2 /> &emsp; Website Designing & Development</li><br></br>
// //                                 <li className="slide-in-Right"> <FaCartShopping /> &emsp; Software Traning</li><br></br>
// //                                 <li className="slide-in-Right"> <FaRegLightbulb /> &emsp; Mobile Application Development</li><br></br>
// //                                 <li className="slide-in-Right"> <ImBin2 /> &emsp; API Development</li><br></br>
// //                                 <li className="slide-in-Right"> <FaCartShopping /> &emsp;Software Maintnance And Support Website Designing</li><br></br>
// //                              </ul>
// //                         </div>
// //                     </div>
// //                 </div>

                

// //         </main>
// //     );
// // };

// // export default Home;



// // ...................................................................

import React from 'react'
import videoSrc from './Images/voilets11.mp4'
import './Home.css'
function Video() {
  return (
    <div>
        <video className="home-video" autoPlay muted loop>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
    </div>
  )
}

export default Video