import React from 'react'
import Gallery1 from '../Component/Gallery1'
import Gallerysection from '../Component/Gallerysection'
function Gallerymain() {
  return (
    <div>
      <Gallery1/>
      <Gallerysection/>
    </div>
  )
}

export default Gallerymain
