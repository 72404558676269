import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from '../src/Component/Header';
import Footer from '../src/Component/Footer';
import Landing_page from '../src/Component/Landing_page';
import Enquiry from '../src/Component/Enquiry';
import About from '../src/Component/AboutUs';
import Client_list from '../src/Component/Client_lists';
import Training_internship from '../src/Component/Training_Internship';
import Contact_us from '../src/Component/Contact_Us'
import SocialMediaLinks from '../src/Component/SocialMediaLinks';
import Yash from '../src/Component/Yash';
import Fullstack from '../src/Component/Fullstack';
import Backend from '../src/Component/Backend';
import Frontend from '../src/Component/Frontend';
import Digital_Marketing from '../src/Component/Digital_Marketing';
import Careers from '../src/Component/Careers';
import Gallery_main from '../src/Component/Gallery_main';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <SocialMediaLinks />
        <Routes>
          <Route path="/" element={<Landing_page />}></Route>
          <Route path='/About' element={<About />}></Route>
          <Route path='/Gallery' element={<Gallery_main/>}></Route>
          <Route path="/Enquiry" element={<Enquiry />}></Route>
          <Route path='/Clients' element={<Client_list />} />
          <Route path='/Career' element= {<Careers/>}/>
          <Route path='/Training_internship' element={<Training_internship />} />
          <Route path='/Contact' element={<Contact_us />} />
          <Route path='/Portfolio' element={<Yash />} />
          <Route path='/Fullstack' element={<Fullstack/>}/>
          <Route path='/Backend' element= {<Backend/>}/>
          <Route path='/Frontend' element= {<Frontend/>}/>
          <Route path='/Digital_Marketing' element= {<Digital_Marketing/>}/>

        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;


